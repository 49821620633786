import {Pipe, PipeTransform, SecurityContext} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({ name: 'keepHtml', pure: false})
export class EscapeHtmlPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {
  }

  public transform(content: any): any {
    return this.sanitizer.bypassSecurityTrustHtml(content);
  }
}
