// new version not used now

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {EscapeHtmlPipe} from './keep-html.pipe';
import {ClearHtmlPipe} from './clear-html.pipe';



@NgModule({
  declarations: [
    EscapeHtmlPipe,
    ClearHtmlPipe,
  ],
  exports: [
    EscapeHtmlPipe,
    ClearHtmlPipe,
  ]
})
export class PipesModule {}
