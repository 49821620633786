import {HttpClient} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {Tools} from '../../constructions/tools';
@Injectable()
export class MapImagesService {

  private tools: Tools;

  constructor(public http: HttpClient) {
    this.tools = new Tools();
  }
  public getFilesName(id: number): Observable<any>  {
    return this.http.post(`${this.tools.pathSite}/file/mapImages/${id}`, id);
  }
}
