import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { Observable } from 'rxjs';
// import 'rxjs/add/operator/toPromise';
// import 'rxjs/add/operator/catch';
import {ImpressionModel} from '../instructions/models/impression.model';
import {Tools} from '../constructions/tools';

@Injectable()
export class FilterService {
  private tools: Tools;
  constructor(public http: HttpClient) {
    this.tools = new Tools();
  }

  public getEstablishedFilterSlider(id: number): Observable<any>  {
    // let data    = JSON.stringify(setting);
    return this.http.get(`${this.tools.pathSite}/getSlider/filter/${id}`);
  }

}
